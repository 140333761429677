import styled from "styled-components";

export const LandingCurrDelParent = styled.div`
  background: #000;
  margin-top: -1px;
`;

export const LandingCurrDel = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 16px;
  left: 0px;
  right: 0px;
  bottom: 24px;
  display: flex;
  padding: 34px 16px;
  width: 100%;
  bottom: -60px;
  & input {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    margin-right: 8px;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  & input.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  input.focus {
    outline: none;
    border: 1px solid #008cff !important;
  }

  & input.error {
    border: 1px solid red;
  }

  .curr1,
  .curr2,
  .curr3 {
    position: absolute;
    width: 92px;
    height: 92px;
    transform: rotate(45deg);
  }

  & .curr1 {
    top: 82px;
    left: 9px;
    z-index: 7;
  }
  & .curr2 {
    top: 82px;
    left: 35px;
    z-index: 6;
  }
  & .curr3 {
    top: 82px;
    left: 58px;
    z-index: 5;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const LandingCurrDelWrap = styled.div`
  display: flex;
  margin: 0 auto;
  background: #fff;
  flex-direction: column;
`;

export const LandingCurrDelLeft = styled.div`
  background: linear-gradient(
    159.48deg,
    #e3fffd 4.69%,
    rgba(230, 237, 255, 0.736176) 27.76%,
    #d6e8f4 92.13%
  );
  border-radius: 14px;
  padding: 24px;
  margin-bottom: 32px;
  position: relative;
`;

export const LandingCurrDelTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  width: 100%;
  color: #000000;
  margin-bottom: 32px;
`;

export const CheckBtn = styled.button`
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  outline: none;
  border: none;
  cursor: pointer;
  width: 102px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LandingCurrDelFlex = styled.div`
  display: flex;
  position: relative;
  height: 120px;

  & img {
    width: 122px;
    height: 122px;
    flex-shrink: 0;
    position: absolute;
  }

  & img:first-child {
    z-index: 3;
    left: 0;
  }

  & img:nth-child(2) {
    z-index: 2;
    left: 21px;
    top: 1px;
  }

  & img:last-child {
    z-index: 1;
    left: 54px;
    top: -3px;
  }
`;

export const LandingCurrDelInfo = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  width: 100%;

  & .bold {
    font-weight: 700;
  }
`;

export const LandingCurrDelCity = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  position: relative;
  margin-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    background: #d9d9d9;
    border-radius: 15px;
    width: 40px;
    height: 4px;
    top: -4px;
    left: 0;
  }
  em {
    color: #22936a;
    margin-bottom: 8px;
    font-weight: 700;
    font-style: normal;
  }
`;

export const LandingCurrDelpara = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 110px;
`;

export const LandingCurrDelList = styled.ul`
  font-size: 14px;
  line-height: 20px;
  line-height: 32px;
  padding-left: 18px;
  gap: 10px;
  margin-bottom: 12px;

  & li {
    position: relative;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 9px;
    background: #a07419;
    border-radius: 14px;
  }

  & .yellowText {
    background: linear-gradient(86.88deg, #9d8523 0%, #dab90b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
  }

  & .greenText {
    background: linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
  }

  & .bold {
    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
  }
`;

export const FootNote = styled(Flex)`
  position: absolute;
  bottom: 38px;
`;

export const PoweredByText = styled.span`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-right: 20px;
`;
