import { useState } from "react";

import { BulletList } from "../BulletList";
import { Markdown } from "../markdown";
import { IPoweredby } from "../poweredBy";
import { BG_COLOR } from "@tm/utility/theme";

import {
  LandingCurrDel,
  LandingCurrDelLeft,
  LandingCurrDelpara,
  LandingCurrDelParent,
  LandingCurrDelWrap,
} from "./index.style";
import { CustomerEngagement } from "../customerEngagement";
import { PoweredByText } from "../poweredBy/index.style";
import { useForeignCurrencyAnimation } from "@tm/ui/hooks";
import { FootNote } from "../LandingCurrDel/LandingCurrDel.style";
import { Image } from "../Image";

interface IForeignCurrencyBenefitsCard {
  title?: string;
  currencyImgs?: any;
  foreignCurrencyBenefitsList?: Array<string>;
  poweredBy?: IPoweredby;
}

interface IForeignCurrencyEngagement {
  title?: string;
  subtitle?: string;
  buyCtaText?: string;
  buyRedirectionUrl?: string;
  deliveryInfo?: string;
  verifyPincodeCtaText?: string;
  buyEvent?: any;
  defaultDeliveryDays: any;
}
interface IForeignCurrencyForex {
  foreignCurrencyBenefitsCard: IForeignCurrencyBenefitsCard;
  foreignCurrencyEngagement: IForeignCurrencyEngagement;
  events: any;
}
const ForeignCurrencyForex = ({
  foreignCurrencyBenefitsCard,
  foreignCurrencyEngagement,
  events,
}: IForeignCurrencyForex) => {
  const [load, setLoad] = useState(false);
  useForeignCurrencyAnimation();

  const { poweredBy, currencyImgs } = foreignCurrencyBenefitsCard;
  const { title, logos } = poweredBy || {};

  const checkPincodeHandler = () => {
    setLoad(!load);
    //@TODO: Need to implement.
  };

  const buyForeignCurrencyHandler = () => {
    //@TODO: Need redirection Url.
  };

  return (
    <LandingCurrDelParent>
      <LandingCurrDel id="curr_ref">
        <LandingCurrDelWrap id="pad_ref">
          <LandingCurrDelLeft id="curr_del_left">
            {foreignCurrencyBenefitsCard?.title && (
              <LandingCurrDelpara>
                <Markdown>{foreignCurrencyBenefitsCard.title}</Markdown>
              </LandingCurrDelpara>
            )}
            <Image id="curr1" className="curr1" {...currencyImgs?.[0]} />
            <Image id="curr2" className="curr2" {...currencyImgs?.[1]} />
            <Image id="curr3" className="curr3" {...currencyImgs?.[2]} />
            <BulletList
              bulletColor={BG_COLOR.bistreBrown}
              list={foreignCurrencyBenefitsCard.foreignCurrencyBenefitsList}
            />
            <FootNote>
              <PoweredByText>{title}</PoweredByText>
              {
                <Image
                  alt={logos?.[0]?.alt}
                  src={poweredBy?.logos?.[0]?.src}
                  width="107"
                  height="37"
                />
              }
            </FootNote>
          </LandingCurrDelLeft>
          <CustomerEngagement
            {...foreignCurrencyEngagement}
            offering="BMF"
            checkPincodeHandler={checkPincodeHandler}
            onClick={buyForeignCurrencyHandler}
            events={events}
          />
        </LandingCurrDelWrap>
      </LandingCurrDel>
    </LandingCurrDelParent>
  );
};

export default ForeignCurrencyForex;
