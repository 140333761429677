import styled from "styled-components";

import {
  TEXT_COLOR,
  BG_COLOR,
  BLUE_COLOR,
  BORDER_COLOR,
  GRADIENT_COLOR,
} from "@tm/utility/theme";

export const LandingCurrDelParent = styled.div`
  background: ${BG_COLOR.black};
  /* height: 614px; */
`;

export const LandingCurrDel = styled.div`
  background: ${BG_COLOR.white};
  padding: 84px 0;
  position: relative;
  margin: 0 auto;
  width: 1200px;
  border-radius: 16px;
  left: 0px;
  right: 0px;
  bottom: 90px;
  display: flex;
  & input {
    background: ${BG_COLOR.white};
    border: 1px solid ${BORDER_COLOR.lightGrey};
    border-radius: 4px;
    margin-right: 8px;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  & input.disable {
    opacity: 0.3;
    pointer-events: none;
  }

  input.focus {
    outline: none;
    border: 1px solid ${BLUE_COLOR[300]} !important;
  }

  & input.error {
    border: 1px solid red;
  }

  .curr1,
  .curr2,
  .curr3 {
    position: absolute;
    width: 140px;
    height: 140px;
    transform: rotate(45deg);
    visibility: visible;
  }

  & .curr1 {
    top: 110px;
    left: 216px;
    z-index: 7;
  }
  & .curr2 {
    top: 110px;
    left: 264px;
    z-index: 6;
  }
  & .curr3 {
    top: 110px;
    left: 310px;
    z-index: 5;
  }
`;

export const LandingCurrDelWrap = styled.div`
  display: flex;
  margin: 0 auto;
  background: ${BG_COLOR.white};
  width: 1200px !important;
`;

export const LandingCurrDelLeft = styled.div`
  background: linear-gradient(
    159.48deg,
    ${GRADIENT_COLOR.lightCyan} 4.69%,
    ${GRADIENT_COLOR.lightSalmon} 27.76%,
    ${GRADIENT_COLOR.lightBlue0} 92.13%
  );
  border-radius: 14px;
  padding: 38px 42px 70px;
  margin-right: 75px;
  visibility: hidden;
  position: relative;
`;

export const LandingCurrDelFlex = styled.div`
  display: flex;
  position: relative;
  height: 120px;

  & img {
    width: 122px;
    height: 122px;
    flex-shrink: 0;
    position: absolute;
  }

  & img:first-child {
    z-index: 3;
    left: 0;
  }

  & img:nth-child(2) {
    z-index: 2;
    left: 21px;
    top: 1px;
  }

  & img:last-child {
    z-index: 1;
    left: 54px;
    top: -3px;
  }
`;

export const LandingCurrDelpara = styled.div`
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: ${TEXT_COLOR.high};
  margin-bottom: 177px;
`;
